import React, { useState, ChangeEvent, useEffect } from "react";

type FileUploadProps = {
  inputId: string;
  selectFileText?: string;
  uploadFileText?: string;
  handleFileUpload: (data: FormData) => Promise<void>;
  isError?: boolean;
};

export const FileUpload: React.FC<FileUploadProps> = ({ inputId, selectFileText, uploadFileText, handleFileUpload, isError }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async (event: any) => {
    event.preventDefault();

    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);


    handleFileUpload(formData);

    setSelectedFile(null);
  };

  useEffect(() => {
    console.log("here20", isError);
    setSelectedFile(null);
  }, [isError]);

  return (
    <>
      <div className="input-group">
        <label htmlFor={`${inputId}`} className="btn btn-primary">
          {selectFileText ?? "Select File"}
        </label>
        <input id={`${inputId}`} type="file" onChange={handleFileChange} className="form-control input-chose-file" value={selectedFile ? undefined : ""} />
        <button className="btn btn-primary me-5" onClick={handleUpload} disabled={!selectedFile}>
          {uploadFileText ?? "Upload File"}
        </button>
      </div>
    </>
  );
};
