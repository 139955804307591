import React, { useState, useEffect, useContext, ChangeEvent } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  CircularProgress,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";
import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { FilterCriteria } from "types/filterCriteria";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { BreadcrumbContext } from "contexts/breadcrumbContext";
import { GranteeSelectionFilterType } from "types/enums";

const NO_ROWS_LABEL = "No T/TA Activities.";
const NO_ROWS_LABEL_FILTERED = "No T/TA Activities match the filter criteria: ";
const NO_ROWS_LABEL_FILTERED_APPROVALS =
  "You have no additional T/TA Activities to Approve. Please deselect the filter in order to see all T/TA Activities.";

type ActivityListProps = {
  data: BaseRecord[];
  isFilterExpanded?: boolean;
  onFilterResults?: () => void;
};

export const ActivityList: React.FC<ActivityListProps> = ({
  data,
  isFilterExpanded,
  onFilterResults,
}) => {
  const [activityData, setActivityData] = useState<BaseRecord[]>([]);
  const [filteredApprovalsData, setFilteredApprovalsActivityData] = useState<
    BaseRecord[]
  >([]);
  const [filteredDataFromOdata, setFilteredActivityDataFromOdata] = useState<
    BaseRecord[] | null
  >(null);
  const [filteredDataForGrid, setFilteredActivityDataForGrid] = useState<
    BaseRecord[]
  >([]);
  const [noRowsLabel, setNoRowsLabel] = useState<string>(NO_ROWS_LABEL);
  const {
    filterActvitiesByPendingApprovals,
    setFilterActvitiesByPendingApprovals: setShouldFilterPendingApprovals,
    getRecordStatusesForUserApproval,
  } = useContext(LoggedInUserContext);
  const { getLookupDescription, getLookupDescriptions } =
    useContext(LookupsContext);

  const { setBreadcrumbs } = useContext(BreadcrumbContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      flex: 0.75,
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <Link
            to={`/activities/read/${encodeURIComponent(
              encryptData(params.id)
            )}`}
            className="text-gray-700 text-hover-primary mb-1"
          >
            TA-{params.row.id}
          </Link>
        );
      },
      valueGetter(params) {
        return "TA-" + params.row.id;
      },
    },
    {
      field: "title",
      headerName: "TITLE",
      headerClassName: "fw-bold",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params: any) => {
        return <div>{params.formattedValue}</div>;
      },
      sortable: true,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      minWidth: 300,
      cellClassName: "wrap-text cell-align-top",
      renderCell: (params: any) => {
        return <div>{params.formattedValue}</div>;
      },
      sortable: true,
    },
    {
      field: "activityStartDate",
      headerName: "DATE",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.activityStartDate
              ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY")
              : "") +
              (params.row.activityEndDate ? " - " : "") +
              (params.row.activityEndDate
                ? dayjs(params.row.activityEndDate).format("MM/DD/YYYY")
                : "")}
          </>
        );
      },
      valueGetter(params) {
        return params.row.activityStartDate
          ? dayjs(params.row.activityStartDate).format("MM/DD/YYYY")
          : "";
        //(params.row.activityEndDate ? " - " : "") +
        //(params.row.activityEndDate ? dayjs(params.row.activityEndDate).format("MM/DD/YYYY") : "")
      },
      sortable: true,

      sortComparator: (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    },
    {
      field: "serviceRequest_Lookup_ServiceTopics",
      headerName: "TOPIC",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {getLookupDescriptions(
              params.row.serviceRequest_Lookup_ServiceTopics
            ).join(" | ")}
          </>
        );
      },
      valueGetter(params) {
        return getLookupDescriptions(
          params.row.serviceRequest_Lookup_ServiceTopics
        ).join(" | ");
      },
      sortable: true,
    },
    {
      field: "taMethods_VirtualCode",
      headerName: "TA METHOD",
      flex: 1,
      cellClassName: "wrap-text cell-align-top",
      renderCell: function (params: any) {
        return (
          <>
            {(params.row.taMethods_VirtualCode
              ? getLookupDescription(params.row.taMethods_VirtualCode)
              : "") +
              (params.row.taMethods_FaceToFaceCode
                ? getLookupDescription(params.row.taMethods_FaceToFaceCode)
                : "")}
          </>
        );
      },
      valueGetter(params) {
        return (
          (params.row.taMethods_VirtualCode
            ? getLookupDescription(params.row.taMethods_VirtualCode)
            : "") +
          (params.row.taMethods_FaceToFaceCode
            ? getLookupDescription(params.row.taMethods_FaceToFaceCode)
            : "")
        );
      },
      sortable: true,
    },
    {
      field: "approvalWorkflowState",
      flex: 1,
      headerName: "APPROVAL STATUS",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return (
          <ApprovalBadge
            status={params.row.approvalWorkflowState?.currentStatus}
          />
        );
      },
      valueGetter(params) {
        return params.row.approvalWorkflowState?.currentStatus;
      },
      sortable: true,
    },

    {
      field: "createDate",
      flex: 1,
      headerName: "CREATE DATE",
      cellClassName: "cell-align-top",
      renderCell: function (params: any) {
        return params.row.createDate;
      },
      valueGetter(params) {
        return params.row.createDate;
      },
      sortable: true,
      hide: true,
    },
  ];

  useEffect(() => {
    setActivityData(data); //save fuller data set to state
    if (filterActvitiesByPendingApprovals) {
      const filteredDataForUserApproval = getFilteredDataForUserApproval(data);
      setFilteredApprovalsActivityData(filteredDataForUserApproval);
      setFilteredActivityDataForGrid(filteredDataForUserApproval);
      setNoRowsLabel(NO_ROWS_LABEL_FILTERED_APPROVALS);
    } else {
      setFilteredActivityDataForGrid(data); //initially set to "full" data set
      setNoRowsLabel(NO_ROWS_LABEL);
    }
  }, [data]);

  const toggleFilterForUserApproval = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setShouldFilterPendingApprovals(event.target.checked);
    if (event.target.checked) {
      setFilteredActivityDataForGrid(
        getFilteredDataForUserApproval(filteredDataFromOdata ?? activityData)
      );
      setNoRowsLabel(NO_ROWS_LABEL_FILTERED_APPROVALS);
    } else {
      setFilteredActivityDataForGrid(filteredDataFromOdata ?? activityData); //set to "full" data set
    }
  };

  const getFilteredDataForUserApproval = (data: BaseRecord[]) => {
    return data?.filter((activity: any) =>
      getRecordStatusesForUserApproval().includes(
        activity.approvalWorkflowState?.currentStatus
      )
    );
  };

  const filterResults = (filterCriteria: FilterCriteria) => {
    setIsLoading(true);
    //setFilterForm(filterCriteria.filterForm);
    if (onFilterResults != null) {
      onFilterResults();
    }
    const getFilteredData = async () => {
      const response = await axiosInstance.get(`${API_URL}/activities`, {
        params: {
          $filter: filterCriteria.oDataFilter,
          // $orderby: "title",
          // $top: 10,
          // $skip: 0,
        },
      });

      //console.log("odata filtered response", response);

      setFilteredActivityDataFromOdata(response?.data);
      if (filterActvitiesByPendingApprovals) {
        setFilteredActivityDataForGrid(
          getFilteredDataForUserApproval(response?.data)
        );
      } else {
        setFilteredActivityDataForGrid(response?.data);
      }
      setNoRowsLabel(
        `${
          filterActvitiesByPendingApprovals
            ? "Within your Pending Approvals: "
            : ""
        } ${NO_ROWS_LABEL_FILTERED} ${filterCriteria.filterDescription}`
      );

      // Set last breadcrumb to be the filter desciption label
      if (filterCriteria.filterDescription) {
        setBreadcrumbs([
          { label: "T/TA Activities", path: "/activities" },
          { label: filterCriteria.filterDescription, path: "" },
        ]);
      } else {
        setBreadcrumbs([{ label: "T/TA Activities", path: "/activities" }]);
      }
      setIsLoading(false);
    };

    getFilteredData();

    //console.log("here20", filteredDataForCharts);

    setFilterExpanded(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    console.log("here60" + new Date().toString(), isLoading);
  }, [isLoading]);

  const clearFilter = () => {
    setFilteredActivityDataFromOdata(null);
    if (filterActvitiesByPendingApprovals) {
      setFilteredActivityDataForGrid(
        getFilteredDataForUserApproval(activityData)
      );
      setNoRowsLabel(NO_ROWS_LABEL_FILTERED_APPROVALS);
    } else {
      setFilteredActivityDataForGrid(activityData);
      setNoRowsLabel(NO_ROWS_LABEL);
    }
    // Clear last breadcrumb to remove the filter desciption label
    setBreadcrumbs([{ label: "T/TA Activities", path: "/activities" }]);
    window.scrollTo(0, 0);
    if (onFilterResults != null) {
      onFilterResults();
    }
  };
  useEffect(() => {
    if (isFilterExpanded != null) {
      setFilterExpanded(isFilterExpanded);
    }
  }, [isFilterExpanded]);

  //if (isLoading === true) {
  //return (
  //  <Box sx={{ display: "flex" }}>
  //    <CircularProgress color="primary" />
  //  </Box>
  //);
  //}

  return (
    <>
      <div className="content flex-row-fluid" id="content">
        <ActivityFilterCriteria
          filterResults={filterResults}
          clearFilter={clearFilter}
          granteeSelectionFilterType={GranteeSelectionFilterType.Invited}
          isFilterExpanded={filterExpanded}
        />
        {isLoading === true && (
          <div>
            <CircularProgress color="primary" />
          </div>
        )}
        {isLoading === false && (
          <div className="card mb-5 mb-xl-10">
            {/* begin::Card header */}
            <div className="card-header border-0 pt-6">
              {/* begin::Card title */}
              <div className="card-title">
                {/* begin::Search */}
                {/* <div className="d-flex align-items-center position-relative my-1"> */}
                {/* begin::Icon */}
                {/* <span className="position-absolute ms-7">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </span> */}
                {/* end::Icon */}

                {/* <label htmlFor="search" className="visually-hidden">
                    Search:{" "}
                  </label>
                  <input type="text" name="search" id="search" className="form-control form-control-solid w-250px ps-14" placeholder="Search Service Requests" />
                </div> */}
                {/* end::Search */}
              </div>
              {/* begin::Card title */}

              {/* begin::Card toolbar */}
              <div className="card-toolbar">
                {/* begin::Toolbar */}
                <div
                  className="d-flex justify-content-end"
                  data-kt-subscription-table-toolbar="base"
                >
                  {/* begin::Filter Button */}
                  {/* <a href="/" className="btn btn-secondary  me-5">
                    <i className="fa-solid fa-filter mb-1"></i>
                    Filter
                  </a> */}
                  {/* end::Filter Button */}

                  {/* begin::Add Button */}
                  <div className="form-check">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={filterActvitiesByPendingApprovals}
                            onChange={toggleFilterForUserApproval}
                          />
                        }
                        label="My Pending Approvals"
                        style={{ fontWeight: "bold" }}
                      />
                    </FormGroup>
                  </div>
                  {/* end::Add Button */}
                </div>
                {/* end::Toolbar */}
              </div>
              {/* end::Card toolbar */}
            </div>
            {/* end::Card header */}

            {/* begin::Card body */}
            <div className="card-body pt-0" style={{ marginTop: -50 }}>
              {/* begin::Table */}

              <div className="table-responsive">
                {/* <DataTable data={filteredData} columns={activityColumns} /> */}

                <CustomDataGrid
                  rows={filteredDataForGrid}
                  columns={columns}
                  pageSize={25}
                  noRowsLabel={noRowsLabel}
                  sortModel={[{ field: "createDate", sort: "desc" }]}
                />
              </div>
              {/* end::Table */}
            </div>
            {/* end::Card body */}
          </div>
        )}
      </div>
    </>
  );
};
