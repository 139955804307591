import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { BaseRecord } from "@refinedev/core";
import { Link } from "react-router-dom";

//Material Design UI + dependencies
import { HSStatFormat } from "types/enums";
import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";

//Local Components
import CustomDataGrid from "components/datagrid";
import { encryptData } from "utils/crypto";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { ApprovalBadge, ActivityFilterCriteria } from "components";
import { ChartData } from "types/chartData";
import { API_URL } from "App";
import { Control } from "react-hook-form";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { string } from "yargs";
import { HSStat } from "components/charts/HSStat";


type ActivityChartProps = {
  dta: BaseRecord[];
  filterForm?: Control;
  isLoading?: boolean;
};







export const ActivityRecipientsStat: React.FC<ActivityChartProps> = ({ dta, filterForm, isLoading }) => {
  const [numActivityRecipients, setNumActivityRecipients] = useState<number>();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const { getLookupDescription } = useContext(LookupsContext);
  const [statVal, setStatVal] = useState<number>(0);



  //NOTE: For column definitions:
  //      "renderCell" is used to resolve what the grid will use for the actual cell value to display
  //      "valueGetter" is used for sorting and filtering because it is the only way to access the actual data value for the column
  //      "getApplyQuickFilterFn" would be used IF we wanted to use a custom filter function for the column outside of what is displayed
  //      Example:
  //        getApplyQuickFilterFn(value, colDef, apiRef) {
  //          return (params) => {
  //            const cellValue = params.row.fiscalYears.map((fy: any) => (!fy.fiscalYear ? "" : dayjs(fy.fiscalYear).year())).join(" | ");
  //            return cellValue.toString().toLowerCase().includes(value.toLowerCase());
  //          };
  //        },
  //      * When the display is just text from the field itself then "valueGetter" is not needed and "renderCell" can be used for both display and filtering/sorting

  useEffect(() => {
    console.log("here30", filterForm?._formValues?.granteeSelections);
    let totalAttendances: number = 0;
    if (dta != null) {
      for (const acc of dta) {
        if (acc.granteeAttendancesConsultation != null) {
          for (const gra of acc.granteeAttendancesConsultation) {


            

            

            let include: boolean = true;

            
            
            if (filterForm?._formValues?.granteeSelections[0].regionCode != null &&
              filterForm?._formValues?.granteeSelections[0].regionCode != "All_Regions" && include === true) {
              include = false;
              if (filterForm?._formValues?.granteeSelections[0].regionCode == gra.regionCode) {
                include = true;
              }
            }
            
            
            if (filterForm?._formValues?.granteeSelections[0].granteeTypeCode != null && 
              filterForm?._formValues?.granteeSelections[0].granteeTypeCode != "All_GranteeTypes" && include === true) {
              include = false;
              if (filterForm?._formValues?.granteeSelections[0].granteeTypeCode == gra?.granteeTypeCode) {
              


                

                include = true;
              }
            }

            
            
            if (filterForm?._formValues?.granteeSelections[0].granteeCode != null &&
              filterForm?._formValues?.granteeSelections[0].granteeCode != "All_Grantees" && include === true) {
            
                
                include = false;
            
              if (filterForm?._formValues?.granteeSelections[0].granteeCode == gra?.granteeCode) {
                
                
                include = true;
              }
            }

            

            if (filterForm?._formValues?.granteeSelections[0].grantProgramCode != null &&
              filterForm?._formValues?.granteeSelections[0].grantProgramCode != "All_GrantPrograms" && include === true) {
            
            
                
                
                include = false;
              
              if (filterForm?._formValues?.granteeSelections[0].grantProgramCode == gra.grantProgramCode) {
                
               
                
                include = true;
              }
            }

            

            if (include === true) {
              totalAttendances = totalAttendances + gra?.numberOfAttendees ?? 0;
            }
          }

        }
        if (acc.granteeAttendancesEvent != null) {
          for (const gra of acc.granteeAttendancesEvent) {
            let include: boolean = true;

      


            if (filterForm?._formValues?.granteeSelections[0].regionCode != null &&
              filterForm?._formValues?.granteeSelections[0].regionCode != "All_Regions" && include === true) {
              include = false;
              if (filterForm?._formValues?.granteeSelections[0].regionCode == gra.regionCode) {
                include = true;
              }
            }
            
            
            if (filterForm?._formValues?.granteeSelections[0].granteeTypeCode != null && 
              filterForm?._formValues?.granteeSelections[0].granteeTypeCode != "All_GranteeTypes" && include === true) {
              include = false;
              if (filterForm?._formValues?.granteeSelections[0].granteeTypeCode == gra?.granteeTypeCode) {
              


                

                include = true;
              }
            }

            
            
            if (filterForm?._formValues?.granteeSelections[0].granteeCode != null &&
              filterForm?._formValues?.granteeSelections[0].granteeCode != "All_Grantees" && include === true) {
            
                
                include = false;
            
              if (filterForm?._formValues?.granteeSelections[0].granteeCode == gra?.granteeCode) {
                
                
                include = true;
              }
            }

            

            if (filterForm?._formValues?.granteeSelections[0].grantProgramCode != null &&
              filterForm?._formValues?.granteeSelections[0].grantProgramCode != "All_GrantPrograms" && include === true) {
            
                include = false;
              
              if (filterForm?._formValues?.granteeSelections[0].grantProgramCode == gra.grantProgramCode) {
                
               
                
                include = true;
              }
            }

            

            if (include === true) {
              totalAttendances = totalAttendances + gra?.numberOfAttendees ?? 0;
            }
          }


            
          }

        }
      
    }
    setStatVal(totalAttendances);
  }, [dta]);












  return <HSStat reportCode="ACTIVITYRECIEPIENTSSTAT" val={statVal} isLoading={isLoading} hsFormat={HSStatFormat.HSFormattedInteger} />
};
