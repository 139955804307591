import React, { useState, useEffect, useContext } from "react";
import { BaseRecord } from "@refinedev/core";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { LookupsContext } from "contexts/lookupsContext";
import { API_URL } from "App";
import { axiosInstance } from "index";
import { FileUpload } from "./fileUpload";
import { aggregateGranteeAttendanceDataByRegion } from "utils/aggregateData";

type GranteeAttendanceEventProps = {
  activityId: number | undefined;
  getValues: UseFormGetValues<BaseRecord>;
  setValue: UseFormSetValue<BaseRecord>;
};

export const GranteeAttendanceEvent: React.FC<GranteeAttendanceEventProps> = ({ activityId, getValues, setValue }) => {
  const [aggregatedGranteeAttendanceData, setAggregatedGranteeAttendanceData] = useState<BaseRecord[]>();
  const { getLookupDescription } = useContext(LookupsContext);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (!activityId) return;
    const granteeAttendancesEvent = getValues("granteeAttendancesEvent");
    if (!granteeAttendancesEvent) return;
    //console.log("getValues('granteeAttendancesEvent')", granteeAttendancesEvent);
    setAggregatedGranteeAttendanceData(aggregateGranteeAttendanceDataByRegion(granteeAttendancesEvent));
  }, [activityId]);

  const handleUpload = async (data: FormData) => {
    try {
      const response = await axiosInstance
        .post(`${API_URL}/activities/${activityId}/attendance/event`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response?.data);
          setValue("granteeAttendancesEvent", response?.data);
          setAggregatedGranteeAttendanceData(aggregateGranteeAttendanceDataByRegion(response?.data));
          //console.log('Success:', response.data);

          setIsError(false);
          setErrorMessage("");
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // outside the range of 2xx
            setIsError(true);

            setErrorMessage(error?.response?.data);
          } else if (error.request) {
            // The request was made but no response was received
            //console.error('No response:', error.request);
          } else {
            // Something happened in setting up the request
            //console.error('Error:', error.message);
          }
        });
    } catch (error) {
      //console.error(error); // Handle the error as per your requirements
    }
  };

  return (
    <>
      {/* begin::T/TA Activity Clients Attended */}
      <div className="d-flex flex-column gap-5 gap-md-7">
        <div className="row g-5">
          <h3 className="h4 mb-0">T/TA Event</h3>
          <div className="col-md-12">
            {isError === true && <div className="alert alert-danger">{errorMessage}</div>}
            <FileUpload inputId="file_upload" selectFileText="Select CSV File" uploadFileText="Upload Attendance Data" handleFileUpload={handleUpload} isError={isError} />
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-stripe">
                <thead className="table-light">
                  <tr className="fw-bold mb-2 align-top">
                    <th id="GranteeGrant" scope="col">
                      Region
                    </th>
                    <th id="AttendeeNum" scope="col">
                      # of Attendees
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {aggregatedGranteeAttendanceData?.map((attendance, index) => (
                    <tr>
                      <td>{getLookupDescription(attendance?.regionCode)}</td>
                      <td>{attendance?.numberOfAttendees}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
      </div>
      {/* end::T/TA Activity Clients Attended */}
    </>
  );
};
