import React, { useContext, useEffect } from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography, Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { LookupsContext } from "contexts/lookupsContext";
import { GranteeSelect, LookupCheckboxes } from "components";
import { GranteeSelectionOptionType, LookupType, LookupCode, RecordStatus, GranteeSelectionFilterType } from "types/enums";
import { FilterCriteria } from "types/filterCriteria";
import { tryAddToStringArray } from "utils/strings";

const defaultFilterValues = {
  granteeSelections: [
    {
      regionCode: GranteeSelectionOptionType.AllRegions,
      granteeTypeCode: GranteeSelectionOptionType.AllGranteeTypes,
      granteeCode: GranteeSelectionOptionType.AllGrantees,
      grantProgramCode: GranteeSelectionOptionType.AllGrantPrograms,
    },
  ],
  population: "",
  audience: "",
  focusArea: "",
  coaching: "",
  statuses: [],
  includeApprovedNotComplete: false,
};

type ActivityFilterCriteriaProps = {
  filterResults: (filter: FilterCriteria) => void;
  clearFilter: () => void;
  granteeSelectionFilterType?: GranteeSelectionFilterType;
  isFilterExpanded?: boolean;
};

export const ActivityFilterCriteria: React.FC<ActivityFilterCriteriaProps> = ({ filterResults, clearFilter, granteeSelectionFilterType, isFilterExpanded }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const { lookups, getLookupCodesForTypeByParentCode, getLookupDescription } = useContext(LookupsContext);

  const { register, control, setValue, getValues, reset } = useForm();

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  const handleSelectAllEvents = (e: any) => {
    e.preventDefault(); //Otherwise it will trigger scroll to top
    setValue("lookup_TAMethods_Virtual", getLookupCodesForTypeByParentCode(LookupType.TAMethods_Virtual, LookupCode.ActivityEvent));
    setValue("lookup_TAMethods_FaceToFace", getLookupCodesForTypeByParentCode(LookupType.TAMethods_FaceToFace, LookupCode.ActivityEvent));
  };

  const handleSelectAllConsultations = (e: any) => {
    e.preventDefault(); //Otherwise it will trigger scroll to top
    setValue("lookup_TAMethods_Virtual", getLookupCodesForTypeByParentCode(LookupType.TAMethods_Virtual, LookupCode.ActivityConsultation));
    setValue("lookup_TAMethods_FaceToFace", getLookupCodesForTypeByParentCode(LookupType.TAMethods_FaceToFace, LookupCode.ActivityConsultation));
  };

  const handleClearFilter = () => {
    reset(defaultFilterValues);
    clearFilter();
  };

  const shouldOverrideStatusSelection = (): boolean => {
    const overrideStatusSelection = getValues("overrideStatusSelection");
    return overrideStatusSelection !== undefined && overrideStatusSelection;
  };

  const handleFilterCriteria = () => {
    let oDataFilters: string[] = [];
    let filterDescriptions: string[] = [];

    //includeApprovedNotComplete
    const overrideStatusSelection: boolean = shouldOverrideStatusSelection();

    //Grantee Selections
    const granteeSelectionFilter = getGranteeSelectionFilter();
    tryAddToStringArray(granteeSelectionFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(granteeSelectionFilter.filterDescription, filterDescriptions);

    //Dates
    const startDateRangeFilter = getStartDateRangeFilter();
    tryAddToStringArray(startDateRangeFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(startDateRangeFilter.filterDescription, filterDescriptions);
    const endDateRangeFilter = getEndDateRangeFilter();
    tryAddToStringArray(endDateRangeFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(endDateRangeFilter.filterDescription, filterDescriptions);

    //Population
    const populationFilter = getLookupFilter("Population", "population", "lookup_ServiceAudience_Populations");
    tryAddToStringArray(populationFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(populationFilter.filterDescription, filterDescriptions);

    //Audience
    const audienceFilter = getLookupFilter("Audience", "audience", "lookup_ServiceAudience_Additional");
    tryAddToStringArray(audienceFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(audienceFilter.filterDescription, filterDescriptions);

    //Focus Area
    const focusAreaFilter = getFocusAreaFilter();
    tryAddToStringArray(focusAreaFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(focusAreaFilter.filterDescription, filterDescriptions);

    //Coaching
    const coachingFilter = getCoachingFilter();
    tryAddToStringArray(coachingFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(coachingFilter.filterDescription, filterDescriptions);

    //Topics
    const topicsFilter = getTopicsFilter();
    tryAddToStringArray(topicsFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(topicsFilter.filterDescription, filterDescriptions);

    //TA Methods
    const taMethodsFilter = getTAMethodsFilter();
    tryAddToStringArray(taMethodsFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(taMethodsFilter.filterDescription, filterDescriptions);

    //Status
    const statusFilter = getStatusFilter(overrideStatusSelection);
    tryAddToStringArray(statusFilter.oDataFilter, oDataFilters);
    tryAddToStringArray(statusFilter.filterDescription, filterDescriptions);

    const filter: FilterCriteria = {
      oDataFilter: oDataFilters.length > 0 ? oDataFilters.join(" AND ") : null,
      filterDescription: filterDescriptions.length > 0 ? filterDescriptions.join(" | ") : null,
      filterForm: control,
    };

    //console.log("filter", filter);
    //debug: TODO: max limit may be around 2048 characters, need to verify
    //console.log("oDataFilter length: ", filter.oDataFilter?.length);

    filterResults(filter);

    setExpanded(false);
  };

  const getGranteeSelectionFilter = (): FilterCriteria => {
    let filter: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filters: string[] = [];
    let descriptions: string[] = [];
    const granteeSelection = getValues("granteeSelections")[0];

    if (granteeSelection !== undefined) {
      // HACK: Checking for empty string on Codes is a hack to get around the fact that the granteeSelections object is not being set properly when the form is reset
      if (granteeSelection.regionCode !== GranteeSelectionOptionType.AllRegions && granteeSelection.regionCode.trim() !== "") {
        filters.push("gs/regionCode eq '" + granteeSelection.regionCode + "'");
        descriptions.push("Region: " + getLookupDescription(granteeSelection.regionCode));
      }
      if (granteeSelection.granteeTypeCode !== GranteeSelectionOptionType.AllGranteeTypes && granteeSelection.granteeTypeCode.trim() !== "") {
        filters.push("gs/granteeTypeCode eq '" + granteeSelection.granteeTypeCode + "'");
        descriptions.push("Grantee Type: " + getLookupDescription(granteeSelection.granteeTypeCode));
      }
      if (granteeSelection.granteeCode !== GranteeSelectionOptionType.AllGrantees && granteeSelection.granteeCode.trim() !== "") {
        filters.push("gs/granteeCode eq '" + granteeSelection.granteeCode + "'");
        descriptions.push("Grantee: " + getLookupDescription(granteeSelection.granteeCode));
      }
      if (granteeSelection.grantProgramCode !== GranteeSelectionOptionType.AllGrantPrograms && granteeSelection.grantProgramCode.trim() !== "") {
        filters.push("gs/grantProgramCode eq '" + granteeSelection.grantProgramCode + "'");
        descriptions.push("Grant Program: " + granteeSelection.grantProgramCode); //Grant Programs are displayed as just their codes
      }
      if (granteeSelectionFilterType === undefined || granteeSelectionFilterType === GranteeSelectionFilterType.Attended) {
        if (filters.length > 0) {
          filters.push("gs/numberOfAttendees gt 0");
        }
      }
    }

    if (granteeSelectionFilterType === undefined || granteeSelectionFilterType === GranteeSelectionFilterType.Attended) {
      //Default filter type is on "Attended"
      filter.oDataFilter = filters.length > 0 ? "(granteeAttendancesConsultation/any(gs: " + filters.join(" and ") + ") or granteeAttendancesEvent/any(gs: " + filters.join(" and ") + "))" : null;
    } else {
      //Filter on "Invited"

      //filter.oDataFilter = filters.length > 0 ? "(granteeAttendancesConsultation/any(gs: " + filters.join(" and ") + ") or granteeAttendancesEvent/any(gs: " + filters.join(" and ") + "))" : null;

      console.log("here10", "(granteeAttendancesConsultation/any(gs: " + filters.join(" and ") + ") or granteeAttendancesEvent/any(gs: " + filters.join(" and ") + "))");

      filter.oDataFilter = filters.length > 0 ? "(granteeSelections/any(gs: " + filters.join(" and ") + "))" : null;
    }
    filter.filterDescription = descriptions.length > 0 ? descriptions.join(" | ") : null;
    return filter;
  };

  const getStartDateRangeFilter = (): FilterCriteria => {
    let filter: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filters: string[] = [];
    let descriptions: string[] = [];
    let dateFrom = getValues("activityStartDateFrom");
    let dateTo = getValues("activityStartDateTo");

    if (dateFrom !== null) {
      filters.push("activityStartDate ge " + dayjs(dateFrom).toISOString());
      descriptions.push("Start Date From: " + dayjs(dateFrom).toDate().toLocaleDateString());
    }

    if (dateTo !== null) {
      filters.push("activityStartDate le " + dayjs(dateTo).toISOString());
      descriptions.push("Start Date To: " + dayjs(dateTo).toDate().toLocaleDateString());
    }

    filter.oDataFilter = filters.length > 0 ? "(" + filters.join(" and ") + ")" : null;
    filter.filterDescription = descriptions.length > 0 ? descriptions.join(" | ") : null;
    return filter;
  };

  const getEndDateRangeFilter = (): FilterCriteria => {
    let filter: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filters: string[] = [];
    let descriptions: string[] = [];
    let dateFrom = getValues("activityEndDateFrom");
    let dateTo = getValues("activityEndDateTo");

    if (dateFrom !== null) {
      filters.push("activityEndDate ge " + dayjs(dateFrom).toISOString());
      descriptions.push("End Date From: " + dayjs(dateFrom).toDate().toLocaleDateString());
    }

    if (dateTo !== null) {
      filters.push("activityEndDate le " + dayjs(dateTo).toISOString());
      descriptions.push("End Date To: " + dayjs(dateTo).toDate().toLocaleDateString());
    }

    filter.oDataFilter = filters.length > 0 ? "(" + filters.join(" and ") + ")" : null;
    filter.filterDescription = descriptions.length > 0 ? descriptions.join(" | ") : null;
    return filter;
  };

  const getLookupFilter = (fieldDescriptionTitle: string, selectFieldName: string, filterFieldName: string): FilterCriteria => {
    let result: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filter: string = "";
    let description: string = "";
    let selectFieldValue = getValues(selectFieldName);

    if (selectFieldValue !== undefined && selectFieldValue?.length > 0) {
      filter = filterFieldName + "/any(a: a eq '" + selectFieldValue + "')";
      description = fieldDescriptionTitle + ": " + getLookupDescription(selectFieldValue);
    }

    result.oDataFilter = filter.length > 0 ? "(" + filter + ")" : null;
    result.filterDescription = description.length > 0 ? description : null;
    return result;
  };

  const getFocusAreaFilter = (): FilterCriteria => {
    let result: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filter: string = "";
    let description: string = "";
    let selectFieldValue = getValues("focusArea");

    if (selectFieldValue !== undefined && selectFieldValue?.length > 0) {
      filter = "focusArea/lookup_FocusAreas/any(a: a eq '" + selectFieldValue + "')";
      description = "Focus Area: " + getLookupDescription(selectFieldValue);
    }

    result.oDataFilter = filter.length > 0 ? "(" + filter + ")" : null;
    result.filterDescription = description.length > 0 ? description : null;
    return result;
  };

  const getCoachingFilter = (): FilterCriteria => {
    let result: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filter: string = "";
    let description: string = "";
    let selectFieldValue = getValues("coaching");

    if (selectFieldValue !== undefined && selectFieldValue?.length > 0) {
      if (selectFieldValue === "1") {
        //why is it string "1" and not number 1?
        filter = "coachingForSuccess eq true";
        description = "Coaching For Success";
      } else {
        filter = "coachingForSuccess eq false";
        description = "Coaching For Success";
      }
    }

    result.oDataFilter = filter.length > 0 ? "(" + filter + ")" : null;
    result.filterDescription = description.length > 0 ? description : null;
    console.log("here20", selectFieldValue === "1" ? "YES" : "NO");

    return result;
  };

  const getTopicsFilter = (): FilterCriteria => {
    let result: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filter: string = "";
    let description: string = "";
    let topics = getValues("lookup_ServiceTopics");
    if (topics !== undefined && topics?.length > 0) {
      filter = "serviceRequest_Lookup_ServiceTopics/any(a: " + topics?.map((code: any) => "a eq '" + code + "'").join(" or ") + ")";
      description = "Topics: " + topics?.map((code: any) => getLookupDescription(code)).join(", ");
    }

    result.oDataFilter = filter.length > 0 ? "(" + filter + ")" : null;
    result.filterDescription = description.length > 0 ? description : null;
    return result;
  };

  const getTAMethodsFilter = (): FilterCriteria => {
    let result: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filters: string[] = [];
    let descriptions: string[] = [];

    const tmv = getValues("lookup_TAMethods_Virtual");
    if (tmv !== undefined && tmv?.length > 0) {
      filters.push(tmv?.map((code: any) => "taMethods_VirtualCode eq '" + code + "'").join(" or "));
      descriptions.push(tmv?.map((code: any) => getLookupDescription(code)).join(", "));
    }

    const tmf = getValues("lookup_TAMethods_FaceToFace");
    if (tmf !== undefined && tmf?.length > 0) {
      filters.push(tmf?.map((code: any) => "taMethods_FaceToFaceCode eq '" + code + "'").join(" or "));
      descriptions.push(tmf?.map((code: any) => getLookupDescription(code)).join(", "));
    }

    result.oDataFilter = filters.length > 0 ? "(" + filters.join(" or ") + ")" : null;
    result.filterDescription = descriptions.length > 0 ? "TA Methods: " + descriptions.join(", ") : null;
    return result;
  };

  const getStatusFilter = (overrideStatusSelection: boolean): FilterCriteria => {
    let result: FilterCriteria = { oDataFilter: null, filterDescription: null, filterForm: control };
    let filter: string = "";
    let description: string = "";

    if (overrideStatusSelection) {
      // disregard any selected statuses and use the following
      filter =
        "approvalWorkflowState/currentStatus eq '" +
        RecordStatus.SubmittedToFiscal +
        "' or approvalWorkflowState/currentStatus eq '" +
        RecordStatus.SubmittedToSAMHSA +
        "' or approvalWorkflowState/currentStatus eq '" +
        RecordStatus.Approved +
        "' or approvalWorkflowState/currentStatus eq '" +
        RecordStatus.InProgress +
        "' or approvalWorkflowState/currentStatus eq '" +
        RecordStatus.Completed +
        "'";
      description = "Status: 'Activities In Progress'";
    } else {
      let statuses = getValues("statuses");
      if (statuses !== undefined && statuses?.length > 0) {
        filter = statuses
          ?.map((status: any) =>
            status === RecordStatus.Approved
              ? "(approvalWorkflowState/currentStatus eq '" + RecordStatus.Approved + "' or approvalWorkflowState/currentStatus eq '" + RecordStatus.InProgress + "')"
              : "approvalWorkflowState/currentStatus eq '" + status + "'"
          )
          .join(" or ");
        description =
          "Status: " +
          statuses
            ?.map((status: any) => {
              switch (status) {
                case RecordStatus.SubmittedToCore:
                  return "Submitted for Approval";
                case RecordStatus.SubmittedToFiscal:
                  return "Approved by CORE";
                case RecordStatus.SubmittedToSAMHSA:
                  return "Approved by FISCAL";
                case RecordStatus.NotApproved:
                  return "Not Approved";
                default:
                  return status; //RecordStatus.Approved & RecordStatus.Complete & RecordStatus.Draft, etc. do not need to be translated
              }
            })
            .join(", ");
      }
    }

    result.oDataFilter = filter.length > 0 ? "(" + filter + ")" : null;
    result.filterDescription = description.length > 0 ? description : null;
    return result;
  };


  useEffect(() => {
    reset(defaultFilterValues);
  }, []);
  
  useEffect(() => {
    if (isFilterExpanded != null) {
      setExpanded(isFilterExpanded);
    }
  }, [expanded, isFilterExpanded]);

  useEffect(() => {}, [control]);

  return (
    <div className={`card ${expanded ? "mb-5 mb-xl-10" : ""}`}>
      <div className="d-flex justify-content-end me-10"></div>
      <Collapse in={expanded}>
        <div className="card-body">
          <div className="form-group row">
            {/* begin::First Row (Region & Grantees) */}
            <div className="row">
              <GranteeSelect register={register} control={control} setValue={setValue} getValues={getValues} index={0} formFieldName="granteeSelections" />
            </div>
            {/* end::First Row (Region & Grantees) */}

            {/* begin::Second Row (Date Range) */}
            <div className="row mt-5">
              <div className="col-md-3">
                <label htmlFor="activityStartDateFrom" className="form-label">
                  Start Date Range (From)
                </label>
                <Controller
                  name="activityStartDateFrom"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value }, ...props }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={onChange}
                        value={value ? dayjs(value) : null}
                        className="form-control datepicker-short"
                        slotProps={{
                          textField: {
                            id: "activityStartDateFrom",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>

              <div className="col-md-3">
                <label htmlFor="activityStartDateTo" className="form-label">
                  Start Date Range (To)
                </label>
                <Controller
                  name="activityStartDateTo"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value }, ...props }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={onChange}
                        value={value ? dayjs(value) : null}
                        className="form-control datepicker-short"
                        slotProps={{
                          textField: {
                            id: "activityStartDateTo",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>

              <div className="col-md-3">
                <label htmlFor="activityEndDateFrom" className="form-label">
                  End Date Range (From)
                </label>
                <Controller
                  name="activityEndDateFrom"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value }, ...props }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={onChange}
                        value={value ? dayjs(value) : null}
                        className="form-control datepicker-short"
                        slotProps={{
                          textField: {
                            id: "activityEndDateFrom",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>

              <div className="col-md-3">
                <label htmlFor="activityEndDateTo" className="form-label">
                  End Date Range (To)
                </label>
                <Controller
                  name="activityEndDateTo"
                  control={control}
                  defaultValue={null}
                  render={({ field: { onChange, value }, ...props }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={onChange}
                        value={value ? dayjs(value) : null}
                        className="form-control datepicker-short"
                        slotProps={{
                          textField: {
                            id: "activityEndDateTo",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
            {/* end::Second Row (Date Range) */}

            {/* begin::Third Row (SPF & Coaching) */}
            <div className="row mt-5">
              <div className="col-md-3">
                <label htmlFor="population" className="form-label">
                  Population
                </label>

                <select id="population" {...register(`population`)} className="form-select">
                  <option value={""}>Select...</option>
                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "ServiceAudience_Populations";
                    })
                    .map((lookup, index) => (
                      <option key={index} value={lookup.lookupCode}>
                        {lookup.lookupDescription}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-3">
                <label htmlFor="audience" className="form-label">
                  Audience
                </label>

                <select id="audience" {...register(`audience`)} className="form-select">
                  <option value={""}>Select...</option>
                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "ServiceAudience_Additional";
                    })
                    .map((lookup, index) => (
                      <option key={index} value={lookup.lookupCode}>
                        {lookup.lookupDescription}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-3">
                <label htmlFor="focusarea" className="form-label">
                  SPF Focus Area
                </label>

                <select id="focusarea" {...register(`focusArea`)} className="form-select">
                  <option value={""}>Select...</option>
                  {lookups
                    .filter((lookup) => {
                      return lookup.lookupType === "FocusAreas";
                    })
                    .map((lookup, index) => (
                      <option key={index} value={lookup.lookupCode}>
                        {lookup.lookupDescription}
                      </option>
                    ))}
                </select>
              </div>

              <div className="col-md-3">
                <label htmlFor="coaching" className="form-label">
                  Coaching for Success
                </label>
                <select id="coaching" {...register(`coaching`)} className="form-select">
                  <option value={""}>Select...</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                  {/* <option value={0}>No</option> */}
                </select>
              </div>
            </div>
            {/* end::Third Row (SPF & Coaching) */}

            <div className="separator separator-dashed my-6"></div>

            {/* begin::Fourth Row (Topic) */}
            <fieldset>
              <div className="row">
                <legend>Topic Area</legend>
                <div className="four-column-list" style={{ marginRight: "-1.225rem" }}>
                  <LookupCheckboxes register={register} lookupType="ServiceTopics" formFieldName="lookup_ServiceTopics" />
                </div>
              </div>
            </fieldset>
            {/* end::Fourth Row (Topic) */}

            <div className="separator separator-dashed my-6"></div>

            {/* begin::Fifth Row (T/TA Methods) */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset id="virtual">
                      <legend>TA Methods (Virtual)</legend>

                      {/* begin::Input */}
                      <LookupCheckboxes register={register} lookupType="TAMethods_Virtual" formFieldName="lookup_TAMethods_Virtual" />
                      {/* end::Input */}
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  <div className="col-md-6">
                    {/* begin::fieldset */}
                    <fieldset id="f2f">
                      <legend className="mt-4 mt-md-0">TA Methods (Face to Face)</legend>

                      {/* begin::Input */}
                      <LookupCheckboxes register={register} lookupType="TAMethods_FaceToFace" formFieldName="lookup_TAMethods_FaceToFace" />
                      {/* end::Input */}
                    </fieldset>
                    {/* end::fieldset */}
                  </div>

                  <div className="mt-3">
                    <div className="fw-semibold text-gray-900 fs-6">
                      <span className="me-1">Selection Options:</span>

                      <a className="fw-bold me-1" href="#" id="eventLink" onClick={handleSelectAllEvents}>
                        Select All Events
                      </a>

                      <span className="me-1"> or </span>

                      <a className="fw-bold me-1" href="#" id="consultationLink" onClick={handleSelectAllConsultations}>
                        Select All Consultations
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                {/* begin::fieldset */}
                <fieldset id="f2f">
                  <legend className="form-label">Status</legend>

                  {/* begin::Input */}
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.Draft} id="status_draft" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_draft">
                      Draft
                    </label>
                  </div>

                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.SubmittedToCore} id="status_sub_app" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_sub_app">
                      Submitted to Core
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.NotApproved} id="status_not_app" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_not_app">
                      Not Approved
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.SubmittedToFiscal} id="status_app_core" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_app_core">
                      Submitted to Fiscal
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.SubmittedToSAMHSA} id="status_app_fiscal" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_app_fiscal">
                      Submitted to SAMHSA
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.Approved} id="status_app" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_app">
                      Approved
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.Completed} id="status_complete" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_complete">
                      Complete
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value={RecordStatus.Withdrawn} id="status_withdrawn" {...register("statuses")} />
                    <label className="form-check-label" htmlFor="status_withdrawn">
                      Withdrawn
                    </label>
                  </div>
                  {/* end::Input */}
                </fieldset>
                {/* end::fieldset */}
              </div>

              {/* begin::Fourth Row (Anomaly) */}

              {/*
              <div className="col-md-3">
                
                <fieldset id="f2f">
                  <legend className="form-label">Activities In Progress</legend>

                  
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="app_not_complete" {...register("overrideStatusSelection")} />
                    <label className="form-check-label" htmlFor="app_not_complete">
                      This includes all activities that were in progress in the date ranges that were provided above. Both start and end date ranges must be the same. This ignores the status column.
                    </label>
                  </div>

                  
                </fieldset>
                
              </div>
              */}

              {/* end::Fourth Row (Anomaly) */}
            </div>
            {/* end::Fifth Row (T/TA Methods) */}
          </div>
        </div>

        {/* begin::Filter Card Footer) */}
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="reset" className="btn btn-link me-10" onClick={handleClearFilter}>
            Reset
          </button>
          <button type="button" className="btn btn-primary btn-outline-primary" id="kt_account_profile_details_submit" onClick={handleFilterCriteria}>
            View Results
          </button>
        </div>
        {/* end::Filter Card Footer) */}
      </Collapse>
    </div>
  );
};
