import React, { useEffect } from "react";
import { UseFormRegister, Control, useFieldArray, Controller, UseFormSetValue, UseFormGetValues } from "react-hook-form";
import { BaseRecord } from "@refinedev/core/dist/interfaces";
import { NumericFormat } from "react-number-format";

type MileageStaffExpenseProps = {
  register: UseFormRegister<BaseRecord>;
  control: Control<BaseRecord, any>;
  setValue: UseFormSetValue<BaseRecord>;
  getValues: UseFormGetValues<BaseRecord>;
  staff: BaseRecord[];
  lookups: BaseRecord[];
};

export const MileageStaffExpense: React.FC<MileageStaffExpenseProps> = ({ register, control, setValue, getValues, staff, lookups }) => {
  const defaultMileageExpense = { staffUserId: "", estimatedMiles: 0, estimatedMileageRateUsd: 0, estimatedTotal: 0, actualMiles: 0, actualMileageRateUsd: 0, actualTotal: 0 };
  const {
    fields: mileageExpenseFields,
    append: appendMileageExpense,
    remove: removeMileageExpense,
  } = useFieldArray({
    control,
    name: "mileageExpenses",
  });

  useEffect(() => {
    control?._formValues?.mileageExpenses?.length === 0 && appendMileageExpense(defaultMileageExpense);
  }, []);

  const handleMileageChange = (field: string, rateField: string, sumField: string, value: string) => {
    let valueConverted = parseFloat(value.replace(/[^0-9.-]/g, ""));
    if (isNaN(valueConverted)) valueConverted = 0;

    if (value === ".") {
      setValue(field, value);
    } else {
      setValue(field, valueConverted);
    }

    const rate = getValues(rateField);
    if (valueConverted && Number(valueConverted) && rate && Number(rate) > 0) {
      setValue(sumField, Number(valueConverted) * Number(rate));
    } else {
      setValue(sumField, 0);
    }
  };

  const handleRateChange = (field: string, mileageField: string, sumField: string, value: string) => {
    let valueConverted = parseFloat(value.replace(/[^0-9.-]/g, ""));
    if (isNaN(valueConverted)) valueConverted = 0;

    setValue(field, valueConverted);
    /* if (value === ".") {
      setValue(field, valueConverted);
    } else {
      setValue(field, valueConverted);
    } */

    const miles = getValues(mileageField);
    if (valueConverted && Number(valueConverted) && miles && Number(miles) > 0) {
      setValue(sumField, Number(valueConverted) * Number(miles));
    } else {
      setValue(sumField, 0);
    }
  };

  return (
    <>
      <h3 className="h4 mb-0">Mileage</h3>

      <div className="col-sm-12">
        <div className="table-responsive">
          <table className="table table-stripe">
            <thead className="table-light">
              <tr className="fw-bold mb-2 align-top">
                <th id="MileMember" scope="col" className="ps-1" style={{ width: "20%" }}>
                  Staff Member
                </th>
                <th id="EstNumMiles" scope="col" style={{ width: "100px" }}>
                  Estimated Total
                  <br />
                  Number of Miles
                </th>
                <th id="EstMileRate" scope="col" style={{ width: "100px" }}>
                  Mileage Rate
                  <br />
                  from GSA
                </th>
                <th id="EstMileAmt" scope="col" style={{ width: "100px" }}>
                  Estimated
                  <br />
                  Amount
                </th>
                <th id="ActNumMiles" scope="col" style={{ width: "100px" }}>
                  Actual Total
                  <br />
                  Number of Miles
                </th>
                <th id="ActMileRate" scope="col" style={{ width: "100px" }}>
                  Most Recent Mileage
                  <br />
                  Rate from GSA
                </th>
                <th id="ActMileAmt" scope="col" style={{ width: "100px" }}>
                  Actual
                  <br />
                  Amount
                </th>
                <th>
                  <span className="sr-only">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {mileageExpenseFields.map((expense, index) => (
                <tr key={index}>
                  <td className="td-150">
                    <select key={expense.id} id="staffPlan" aria-labelledby="MileMember" {...register(`mileageExpenses[${index}].staffUserId` as const, { required: false })} className="form-select">
                      <option value="">Select...</option>
                      {staff?.map((user, index) => (
                        <option key={index} value={user.userId}>
                          {user.firstName} {user.lastName}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <Controller
                      key={expense.id}
                      name={`mileageExpenses[${index}].estimatedMiles`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          aria-labelledby="EstNumMiles"
                          className="form-control td-150"
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          allowLeadingZeros
                          isAllowed={(values) => {
                            if (!values.value) return true;
                            let { floatValue } = values;
                            if (floatValue !== undefined && values.value === ".") {
                              floatValue = 0;
                            }
                            return floatValue !== undefined ? floatValue < 1000000 : true;
                          }}
                          onValueChange={(e: any) =>
                            handleMileageChange(`mileageExpenses[${index}].estimatedMiles`, `mileageExpenses[${index}].estimatedMileageRateUsd`, `mileageExpenses[${index}].estimatedTotal`, e.value)
                          }
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      key={expense.id}
                      name={`mileageExpenses[${index}].estimatedMileageRateUsd`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          aria-labelledby="EstMileRate"
                          className="form-control td-150"
                          prefix={"$"}
                          thousandSeparator={true}
                          decimalScale={3}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          allowLeadingZeros={true}
                          //fixedDecimalScale={true}
                          isAllowed={(values) => {
                            if (!values.value) return true;
                            let { floatValue } = values;
                            //if (floatValue !== undefined && values.value === ".") {
                            //  floatValue = 0;
                            //}
                            return floatValue !== undefined ? floatValue < 100 : true;
                          }}
                          onValueChange={(e: any) =>
                            handleRateChange(`mileageExpenses[${index}].estimatedMileageRateUsd`, `mileageExpenses[${index}].estimatedMiles`, `mileageExpenses[${index}].estimatedTotal`, e.value)
                          }
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      key={expense.id}
                      name={`mileageExpenses[${index}].estimatedTotal`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          aria-labelledby="EstMileAmt"
                          className="form-control-plaintext td-100"
                          prefix={"$"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          disabled={true}
                        />
                      )}
                    />
                  </td>

                  <td>
                    <Controller
                      key={expense.id}
                      name={`mileageExpenses[${index}].actualMiles`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          aria-labelledby="ActNumMiles"
                          className="form-control td-150"
                          thousandSeparator={true}
                          decimalScale={2}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          allowLeadingZeros
                          isAllowed={(values) => {
                            if (!values.value) return true;
                            let { floatValue } = values;
                            if (floatValue !== undefined && values.value === ".") {
                              floatValue = 0;
                            }
                            return floatValue !== undefined ? floatValue < 1000000 : true;
                          }}
                          onValueChange={(e: any) =>
                            handleMileageChange(`mileageExpenses[${index}].actualMiles`, `mileageExpenses[${index}].actualMileageRateUsd`, `mileageExpenses[${index}].actualTotal`, e.value)
                          }
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      key={expense.id}
                      name={`mileageExpenses[${index}].actualMileageRateUsd`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          aria-labelledby="ActMileRate"
                          className="form-control td-150"
                          prefix={"$"}
                          thousandSeparator={true}
                          decimalScale={3}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          allowLeadingZeros
                          isAllowed={(values) => {
                            if (!values.value) return true;
                            let { floatValue } = values;
                            //if (floatValue !== undefined && values.value === ".") {
                            //  floatValue = 0;
                            //}
                            return floatValue !== undefined ? floatValue < 100 : true;
                          }}
                          onValueChange={(e: any) =>
                            handleRateChange(`mileageExpenses[${index}].actualMileageRateUsd`, `mileageExpenses[${index}].actualMiles`, `mileageExpenses[${index}].actualTotal`, e.value)
                          }
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      key={expense.id}
                      name={`mileageExpenses[${index}].actualTotal`}
                      control={control}
                      render={({ field: { onChange, value }, ...props }) => (
                        <NumericFormat
                          aria-labelledby="ActMileAmt"
                          className="form-control-plaintext td-100"
                          prefix={"$"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          defaultValue={0}
                          value={value}
                          disabled={true}
                        />
                      )}
                    />
                  </td>
                  <td style={{ minWidth: "40px", paddingTop: "2%" }}>
                    <a key={expense.id} hidden={getValues(`mileageExpenses`).length === 1} style={{ cursor: "pointer" }} onClick={() => removeMileageExpense(index)}>
                      <i aria-hidden="true" className="fa-solid fa-trash-can" title="Remove"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Add More */}
      <div className="col-sm-12 mt-0 mb-5">
        <button type="button" className="btn btn-sm btn-dark" onClick={() => appendMileageExpense(defaultMileageExpense)}>
          Add More
        </button>
      </div>
    </>
  );
};
