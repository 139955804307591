import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { LoggedInUserContext } from "contexts/loggedInUserContext";

import { LookupsContext } from "contexts/lookupsContext";
import { RecordStatus } from "types/enums";
import { BaseRecord } from "@refinedev/core";
import { HourlyExpensesTypes } from "../../types/enums";

interface HourlyExpenseTotals {
  [key: string]: { estimatedHours: number; actualHours: number; estimatedTotal: number; actualTotal: number };
}

interface ExpenseTotalsStaff {
  staffUserId: string;

  staffUserName: string;
  estimatedDays: number;
  estimatedTotal: number;
  actualTotal: number;
  expenseCategory: string;
  expenseCategoryCode: string;
  estimatedMiles: number;
  actualMiles: number;
  staffRateUsd: number;
  estimatedHours: number;
  actualHours: number;
}

interface HourlyStaffExpenseViewProps {
  hourlyExpenses: any[] | undefined;
  activityData?: any[] | undefined;
  isActivityView?: boolean | undefined;
}

export const HourlyStaffExpenseView: React.FC<HourlyStaffExpenseViewProps> = ({ hourlyExpenses, activityData, isActivityView }) => {
  const [hourlyExpenseTotalsByStaffUserName, setHourlyExpenseTotalsByStaffUserName] = useState<HourlyExpenseTotals>({});
  const [totalEstimatedHours, setTotalEstimatedHours] = useState(0);
  const [totalActualHours, setTotalActualHours] = useState(0);
  const [totalEstimatedTotals, setTotalEstimatedTotals] = useState(0);
  const [totalActualTotals, setTotalActualTotals] = useState(0);
  const { userPermissions } = useContext(LoggedInUserContext);
  const { getLookupDescription } = useContext(LookupsContext);

  const [staffExpenses, setStaffExpenses] = useState<ExpenseTotalsStaff[]>([]);

  const [actualActivityHours, setActualActivityHours] = useState(0.0);
  const [actualDeliveryHours, setActualDeliveryHours] = useState<number>(0);

  useEffect(() => {
    if (hourlyExpenses != null) setExpenseTotals(hourlyExpenses);

    


  }, [hourlyExpenses]);

  const setExpenseTotals = (hourlyExpenses: any[] | undefined) => {
    if (hourlyExpenses === null) return;
    let expenseTotalsByStaffUserName: HourlyExpenseTotals = {};
    // Hourly Expenses

    let tmpStaffExpenses: ExpenseTotalsStaff[] = [];
    let tmpDeliveryHours: number = 0;
    hourlyExpenses?.forEach((expense: any) => {
      let acc: any = null;

      if (activityData != null) {
        acc = activityData.find((itm) => itm.id === expense.activityId);
      }

      if (
        (acc != null &&
          ((acc.approvalWorkflowState != null && 
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && 
            acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved && 
            acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) ||
            isActivityView === true)) ||
        acc == null
      ) {
        if (expenseTotalsByStaffUserName[expense.staffUserName]) {
          expenseTotalsByStaffUserName[expense.staffUserName].estimatedHours += expense.estimatedHours;
          expenseTotalsByStaffUserName[expense.staffUserName].actualHours += expense.actualHours;
          expenseTotalsByStaffUserName[expense.staffUserName].estimatedTotal += expense.estimatedTotal;
          expenseTotalsByStaffUserName[expense.staffUserName].actualTotal += expense.actualTotal;
        } else {
          expenseTotalsByStaffUserName[expense.staffUserName] = {
            estimatedHours: expense.estimatedHours,
            actualHours: expense.actualHours,
            estimatedTotal: expense.estimatedTotal,
            actualTotal: expense.actualTotal,
          };
        }

        if             (acc != null &&
              ((acc.approvalWorkflowState != null && 
                acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && 
                acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved && 
                acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) ||
              isActivityView === true) ||
          acc == null
        ) {
          let usrIdx: number = tmpStaffExpenses.findIndex(
            (exp) => exp.staffUserId === expense.staffUserId && exp.expenseCategoryCode === expense.subTypeCode && exp.staffRateUsd === expense.staffRateUsd
          );

          if (usrIdx > -1) {
            tmpStaffExpenses[usrIdx] = {
              staffUserId: expense.staffUserId,
              staffUserName: expense.staffUserName,
              estimatedDays: tmpStaffExpenses[usrIdx].estimatedDays + expense.estimatedDays,
              estimatedTotal: tmpStaffExpenses[usrIdx].estimatedTotal + expense.estimatedTotal,
              actualTotal: tmpStaffExpenses[usrIdx].actualTotal + expense.actualTotal,
              expenseCategory: getLookupDescription(expense.subTypeCode),
              expenseCategoryCode: expense.subTypeCode,
              estimatedMiles: tmpStaffExpenses[usrIdx].estimatedMiles + expense.estimatedMiles,
              actualMiles: tmpStaffExpenses[usrIdx].actualMiles + expense.actualMiles,
              staffRateUsd: expense.staffRateUsd,
              estimatedHours: tmpStaffExpenses[usrIdx].estimatedHours + expense.estimatedHours,
              actualHours: tmpStaffExpenses[usrIdx].actualHours + expense.actualHours,
            };
          } else {
            tmpStaffExpenses.push({
              staffUserId: expense.staffUserId,
              staffUserName: expense.staffUserName,
              estimatedDays: expense.estimatedDays,
              estimatedTotal: expense.estimatedTotal,
              actualTotal: expense.actualTotal,
              expenseCategory: getLookupDescription(expense.subTypeCode),
              expenseCategoryCode: expense.subTypeCode,
              estimatedMiles: expense.estimatedMiles,
              actualMiles: expense.actualMiles,
              staffRateUsd: expense.staffRateUsd,
              estimatedHours: expense.estimatedHours,
              actualHours: expense.actualHours,
            });
          }
        }
      }

      let proceedDeliveryHours: boolean = false;
      
      if (acc != null && acc.approvalWorkflowState != null && 
                acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft && 
                acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
                acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn) {
        proceedDeliveryHours = true;
        console.log("here10", hourlyExpenses);
        
      }

      if (isActivityView === true) {
        proceedDeliveryHours = true;
      }
      
      

      if (proceedDeliveryHours === true) {
        if (expense?.subTypeCode === HourlyExpensesTypes.Delivery) {
          tmpDeliveryHours = tmpDeliveryHours + (expense.actualHours != null ? expense.actualHours : 0);
        }
      }
    });

    setActualDeliveryHours(tmpDeliveryHours);

    //if (tmpStaffExpenses.find((itm) => itm.

    setStaffExpenses(tmpStaffExpenses);

    const tmpSortedExpenseTotalsByStaffUserName = Object.entries(expenseTotalsByStaffUserName).sort((a, b) => {
      return a[0].localeCompare(b[0]);
    });

    const sortedHourlyExpenseTotals = Object.fromEntries(tmpSortedExpenseTotalsByStaffUserName) as HourlyExpenseTotals;

    setHourlyExpenseTotalsByStaffUserName(sortedHourlyExpenseTotals);

    //sum totals for all expenses for this activity
    let totalEstimatedHours = 0;
    let totalActualHours = 0;
    let totalEstimatedTotals = 0;
    let totalActualTotals = 0;
    Object.keys(expenseTotalsByStaffUserName).forEach((key) => {
      totalEstimatedHours += expenseTotalsByStaffUserName[key].estimatedHours;
      totalActualHours += expenseTotalsByStaffUserName[key].actualHours;
      totalEstimatedTotals += expenseTotalsByStaffUserName[key].estimatedTotal;
      totalActualTotals += expenseTotalsByStaffUserName[key].actualTotal;
    });
    setTotalEstimatedHours(totalEstimatedHours);
    setTotalActualHours(totalActualHours);
    setTotalEstimatedTotals(totalEstimatedTotals);
    setTotalActualTotals(totalActualTotals);

    if (activityData != null) {
      setActualActivityHours(0);
      let tmpActualActivityHours: number = 0;
      activityData.forEach((acc) => {
        if (
          acc != null &&
          acc.approvalWorkflowState != null &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Draft &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.NotApproved &&
          acc.approvalWorkflowState.currentStatus !== RecordStatus.Withdrawn
        ) {
          tmpActualActivityHours = tmpActualActivityHours + acc.actualActivityHours;
        }
      });

      setActualActivityHours(tmpActualActivityHours);
    }
  };

  return (
    <>
      <h3 className="h4 mb-3 py-2 text-bg-light">Staff Hours</h3>

      <table className="table hs-table-xs">
        <thead>
          <tr className="fw-bold">
            <th scope="col">Staff Member</th>
            <th scope="col">Type</th>
            <th scope="col">Estimated Hours</th>
            <th scope="col">Actual Hours</th>
            <th scope="col" hidden={!userPermissions.canViewStaffRates}>
              Rate
            </th>
            <th scope="col" hidden={!userPermissions.canViewStaffRates}>
              Estimated Totals
            </th>
            <th scope="col" hidden={!userPermissions.canViewStaffRates}>
              Actual Totals
            </th>
          </tr>
        </thead>
        <tbody>
          {staffExpenses
            ?.sort((a, b) => a.staffUserName.localeCompare(b.staffUserName))
            .map((expense: any, index: number) => (
              <tr key={index}>
                <td>{expense.staffUserName}</td>
                <td>{expense.expenseCategory}</td>
                <td>{Number(expense.estimatedHours).toLocaleString("en-US")}</td>
                <td>{Number(expense.actualHours).toLocaleString("en-US")}</td>
                <td>{userPermissions.canViewStaffRates === true && <span>{Number(expense.staffRateUsd).toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>}</td>
                <td>{userPermissions.canViewStaffRates === true && <span>{Number(expense.estimatedTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>}</td>
                <td>{userPermissions.canViewStaffRates === true && <span>{Number(expense.actualTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <h3 className="h4 mt-5 mb-3 py-2 text-bg-light">Actual Delivery Hours</h3>

      <div className="row">
        <div className="col">
          <p className="text-black fs-6 mb-6">
            {actualDeliveryHours} hour{actualDeliveryHours > 1 && actualDeliveryHours !== 0 ? "s" : ""}
          </p>
        </div>
      </div>

      <h3 className="h4 mb-3 py-2 text-bg-light">Total Staff Hours</h3>
            <div className="col-sm-12">
        {/* begin::Inputs */}
        <table className="table hs-table-xs">
          {/* <caption className="sr-only text-black">Expense Totals for Staff Members</caption> */}
          <thead className="">
            <tr className="fw-bold">
              <th scope="col" className="w-20">
                Staff Member
              </th>
              <th scope="col" className="w-20">Estimated Hours</th>
              <th scope="col" className="w-20">Actual Hours</th>
              <th scope="col" hidden={!userPermissions.canViewStaffRates}>
                Estimated Total
              </th>
              <th scope="col" hidden={!userPermissions.canViewStaffRates}>
                Actual Total
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(hourlyExpenseTotalsByStaffUserName).map((key: string, index: number) => (
              <tr key={index}>
                <td scope="row">{key}</td>
                <td>{Number(hourlyExpenseTotalsByStaffUserName[key].estimatedHours).toLocaleString("en-US")}</td>
                <td>{Number(hourlyExpenseTotalsByStaffUserName[key].actualHours).toLocaleString("en-US")}</td>
                <td>
                  {userPermissions.canViewStaffRates === true && (
                    <span>{Number(hourlyExpenseTotalsByStaffUserName[key].estimatedTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>
                  )}
                </td>
                <td>
                  {userPermissions.canViewStaffRates === true && (
                    <span>{Number(hourlyExpenseTotalsByStaffUserName[key].actualTotal).toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="table-footer-divider">
            <tr className="fw-bold">
              <th scope="row">Totals</th>
              <td>{Number(totalEstimatedHours).toLocaleString("en-US")}</td>
              <td>{Number(totalActualHours).toLocaleString("en-US")}</td>
              <td>{userPermissions.canViewStaffRates === true && <span>{Number(totalEstimatedTotals).toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>}</td>
              <td>{userPermissions.canViewStaffRates === true && <span>{Number(totalActualTotals).toLocaleString("en-US", { style: "currency", currency: "USD" })}</span>}</td>
            </tr>
          </tfoot>
        </table>
        {/* end::Input */}
      </div>
    </>
  );
};
